@import "../../theme/vars";

.wrapper_slider_home {
  position: relative;
  .slick-slider {
    &:hover .slick-prev,
    &:hover .slick-next {
      opacity: 1 !important;
      box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.1);
    }
  }

  .slick-slide {
    display: block;
    // padding: 1.5rem 1rem;
    img{
      width: 100%;
      // border-radius: .5rem;
      // box-shadow: 0 2px 8px rgba($color: #000000, $alpha: 0.05);
    }
    .sld_mobile{
      display: none;
    }
  }

  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    background: #ffffff;
    position: absolute;
    top: calc(50% - 55px);
    display: block;
    width: 30px;
    height: 70px;
    padding: 0;
    z-index: 9;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    border-radius: 0 0.35rem 0.35rem 0;
    opacity: 0.25;
    -webkit-transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
    transition: 0.4s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  }

  .slick-prev {
    left: 0;
    &:hover:before {
      border-right: 8px solid $primary;
    }
  }
  .slick-next {
    right: 0;
    border-radius: 0.35rem 0 0 0.35rem;
    &:hover:before {
      border-left: 8px solid $primary;
    }
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
  }
  .slick-prev:before,
  .slick-next:before {
    content: "";
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 4px);
    z-index: 9;
  }

  .slick-prev:before {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 8px solid #999999;
    border-bottom: 7px solid transparent;
  }

  .slick-next:before {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 8px solid #999999;
    border-bottom: 7px solid transparent;
  }
}
