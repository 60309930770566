@import "../../theme/vars";

.wrapper_item_product {
    margin-bottom: 2rem;
    position: relative;

    .content_item_product {
        background: #ffffff;
        padding: 0.75rem;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
        border: 1px solid #e9eef3;
        border-radius: 10px;

        .box_img_product {
            margin-bottom: 0.5rem;
            position: relative;

            & > img {
                border-radius: 10px;
            }
        }

        .box_ttls_item {
            display: flex;
            justify-content: space-between;
            align-self: start;

            .ttl_item_products {
                min-height: 55px;
                font-size: 0.9rem;
            }

            .btn_info_items {
                color: $black;
                cursor: pointer;
                padding-left: .15rem;

                &:hover {
                    color: $primary_hover;
                }
            }
        }

        .box_info_product {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .box_price_product {
                color: $primary;

                .label_x {
                    font-size: 0.75rem;
                    font-weight: 600;
                }

                .price_product {
                    font-weight: 600;
                    font-size: 1.4rem;
                    letter-spacing: -.5px;
                }
            }

            .box_btn_add {
                .btn_add_prod {
                    padding: 0.45rem 0.5rem;
                    border: 0;
                    color: #ffffff;
                    background: $primary;
                    border-radius: 0.4rem;
                    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);

                    &:active,
                    &:hover,
                    &:focus {
                        background: $primary_hover;
                        outline: none !important;
                    }
                }
            }
        }
    }

    .content_tooltip {
        display: none;
        background: $bg;
        box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);
        padding: 1rem 1rem .5rem .5rem;
        border-radius: .3rem;
        font-size: .7rem;
        position: absolute;
        top: 0;
        left: 0;
        min-width: 220px;
        z-index: 9;
        text-align: justify;

        .btnclose_info {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 1rem;
            color: $primary;
            cursor: pointer;
        }

        &.is-show {
            display: block;
        }
    }
}
