@import "../../theme/vars";

.box_paymethod {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  .item_paymethod {
    background: #ffffff;
    margin: 0.35rem;
    display: flex;
    justify-content: center;
    padding: 0;
    border-radius: 0.35rem;
    border: 1px solid #e4edf8;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }
    img {
      height: 40px;
    }
  }
}
