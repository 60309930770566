.wrapper-credomatic-gt {
    display: grid;
    grid-template-columns: 50vw 50vw;

    h1 {
        color: #646468;
        color: #646468;
        font-size: 1.5em;
        font-weight: 600;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
    }

    .data-side {
        justify-self: end;
        margin-right: 1vw;

        .data-content {
            width: 25vw;
            background: #f1f1f1;
            border-radius: 20px;
            text-align: left;
            padding: 1vh 3vw 1vh 3vw;

            label {
                color: #2273c1;
                font-weight: bold;
                margin: 1vh 0vw 0vh 0vw;
            }

            .data-p {
                color: #646468;
                font-weight: bold;
                margin: 0vw 0vw 2vw 0vw;
            }

            .amount-p {
                font-size: 25px;
                color: #2273c1;
                font-weight: bold;
                margin: 0vh 0vw 2vh 0vw;
            }
        }

        .cancel-button-content {
            width: 25vw;
            text-align: start;

            button {
                color: #ff0000 !important;
                margin: 1rem 0 !important;
                width: 50% !important;
                background: white;
                padding: 0.6rem 0 0.6rem 0;
                border: 1.2px solid #ff0000;
                border-radius: 5px;
                font-family: "Ubuntu", sans-serif;
                font-size: 1rem;
                font-weight: bold;

                &:hover {
                    color: white !important;
                    background: #ff0000 !important;
                }
            }
        }
    }

    .payment-side {
        margin-left: 1vw;

        .payment-content {
            width: 25vw;
            background: #f1f1f1;
            border-radius: 20px;
            text-align: left;
            padding: 1vh 3vw 1vh 3vw;

            p {
                color: #2273c1;
                font-weight: bold;
                margin: 1vh 0vw 0vh 0vw;
            }

            .payment-card {
                background: transparent !important;
                color: transparent;
                border: none !important;
                width: 20% !important;
                padding: 0.8rem 1.5rem !important;
                font-size: 1rem !important;
                font-family: 'Ubuntu', sans-serif !important;
                margin: 0.5rem 0 !important;
                border-radius: 10px !important;
                position: absolute;
                letter-spacing: 0.5px;
            }

            .payment-card-display {
                background: white !important;
                border: none !important;
                width: 100% !important;
                padding: 0.8rem 1.5rem !important;
                font-size: 1rem !important;
                font-family: 'Ubuntu', sans-serif !important;
                margin: 0.5rem 0 !important;
                border-radius: 10px !important;
                color: #646468;
            }

            .cardShow-label {
                color: #646468;
            }

            .payment-date {
                background: white !important;
                border: none !important;
                min-width: auto !important;
                padding: 0.8rem 1rem !important;
                font-size: 1rem !important;
                font-family: 'Ubuntu', sans-serif !important;
                margin: 0.5rem 1rem 0.5rem 0 !important;
                border-radius: 10px !important;
                width: 32% !important;
                color: #646468;
            }

            .payment-code {
                background: white !important;
                border: none !important;
                min-width: auto !important;
                padding: 0.8rem 1.5rem !important;
                font-size: 1rem !important;
                font-family: 'Ubuntu', sans-serif !important;
                margin: 0.5rem 0 !important;
                border-radius: 10px !important;
                width: 40% !important;
                color: #646468;
            }

            .card-i {
                font-size: 2rem;
                padding: 0vh 1vw 0vh 1vw;
            }

            .card-text {
                font-size: 10px;
                width: 33%;
                display: -webkit-inline-box;
                color: #646468;
            }

            .payment-button-enabled {
                color: white !important;
                margin: 1rem 0 1rem 0 !important;
                width: 100% !important;
                padding: 0.6rem 1.5rem !important;
                border: none !important;
                border-radius: 13px !important;
                font-family: "Ubuntu", sans-serif !important;
                font-size: 1.2rem !important;
                background: #2273c1 !important;
            }

            .payment-button-disabled {
                color: white !important;
                margin: 1rem 0 1rem 0 !important;
                width: 100% !important;
                padding: 0.6rem 1.5rem !important;
                border: none !important;
                border-radius: 13px !important;
                font-family: "Ubuntu", sans-serif !important;
                font-size: 1.2rem !important;
                background: #A9A9A9 !important;
            }
        }

        .payment-img-content {
            width: 25vw;
            text-align: end;

            img {
                width: 10vw;
            }
        }
    }

    .information-side {
        justify-self: end;
        margin-right: 1vw;

        .information-content {
            text-align: justify;
            width: 25vw;
            padding: 1vh 0vw 1vh 0vw;

            .information-title {
                color: #646468;
                font-weight: bold;
                font-size: 15px;
            }

            .information-text {
                margin-block-start: 0vh;
                color: #646468;
                font-size: 13px;
            }
        }
    }
}

@media (max-width:1180px) {
    .wrapper-credomatic-gt {
        .data-side {
            .data-content {
                width: 40vw;
            }

            .cancel-button-content {
                width: 40vw;
            }
        }

        .payment-side {
            .payment-content {
                width: 40vw;

                .payment-card {
                    width: 35% !important;
                }
            }

            .payment-img-content {
                width: 40vw;

                img {
                    width: 15vw;
                }
            }
        }

        .information-side {
            .information-content {
                width: 40vw;
            }
        }
    }
}

@media (max-width:767px) {
    .wrapper-credomatic-gt {
        display: block;

        .data-side {
            margin-right: 0vw;
            text-align: -webkit-center;
            text-align: -moz-center;

            .data-content {
                width: 80vw;
            }

            .cancel-button-content {
                width: 80vw;
            }
        }

        .payment-side {
            margin-right: 0vw;
            text-align: -webkit-center;
            text-align: -moz-center;

            .payment-content {
                width: 80vw;

                .payment-card {
                    width: 78% !important;
                }
            }

            .payment-img-content {
                width: 80vw;

                img {
                    width: 20vw;
                }
            }
        }

        .information-side {
            margin-right: 0vw;
            text-align: -webkit-center;
            text-align: -moz-center;

            .information-content {
                width: 80vw;
            }
        }
    }
}
