@import "../../theme/vars";

.box_container_hub {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &.four {
        .item_provaider_hub {
            width: 25%;
        }
    }

    &.three {
        .item_provaider_hub {
            width: 33.3%;
        }
    }

    &.two {
        .item_provaider_hub {
            width: 50%;
        }
    }

    &.one {
        .item_provaider_hub {
            width: 100%;
        }
    }

    .item_provaider_hub {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .item_content {
            text-align: center;
            padding: 2rem 0.25rem;
            margin: 0;
            position: relative;
            /*&:hover img {
        transform: scale(1.1);
      }*/
            & > img {
                height: 225px;
                transition: transform 0.3s;
            }
        }
    }
}
