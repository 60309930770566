@import "../../theme/vars";

.contain_providers {
  width: calc(100% - 270px);
  margin-left: 20px;
  .contain_info_provaider {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .box_info_provaider {
      color: #ffffff;
      text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);
      .ttls_provider {
        font-family: $font_ttls;
        font-weight: 600;
      }
      .info_provider {
        font-weight: 300;
        max-width: 475px;
        font-size: 0.95rem;
        padding-right: 1.25rem;
      }
    }
    .box_logo_provaider {
      min-width: 150px;
      text-align: right;
      margin-bottom: 1rem;
      & > img {
        height: 50px;
      }
    }
  }

  .wrapper_products_provider {
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    .wrapper_categ_mobile{
      display: none;
    }
    .content_products_provider {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
